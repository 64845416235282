import React from "react"
import { Section, Columns } from "../../components/Containers"
import { Image, Text } from "../../components/Core"

function BannerSection({ text, image, colorBack }) {
  return (
    <Section colorBack={colorBack} zeroBottomMobile>
      <Columns sideColumnsSize={3}>
        <div className="column">
          <Text text={text} />
        </div>
      </Columns>
      <Columns className="mt-2" sideColumnsSize={2}>
        <div className="column">
          <Image className="block" publicId={image} />
        </div>
      </Columns>
    </Section>
  )
}

export default BannerSection
