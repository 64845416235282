import React from "react"
import { Section } from "../../components/Containers"
import { Text } from "../../components/Core"

function OurMission(props) {
  return (
    <Section className="our-mission">
      <div
        style={{ width: "100%" }}
        className={`columns has-text-centered-tablet`}>
        <div className={`column is-${props.sideColumnIs}`}></div>
        <div className="column">
          <Text text={props.post.ourMission} />{" "}
        </div>
        <div className={`column is-${props.sideColumnIs}`}></div>
      </div>
    </Section>
  )
}

export default OurMission
